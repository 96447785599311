<template>
    <v-container>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Rel. Composições Por Etapa</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>


                <v-spacer></v-spacer>

            </v-toolbar>
            <v-progress-linear v-if="loading_table" indeterminate></v-progress-linear>

            <v-simple-table  class="mt-4" v-for="stage in stages" :key="stage.id" dense>
                <thead>
                    <tr>
                        <td colspan="6" class="text-left" style="background:#1976d2 ;color: white">{{ stage.code + " - " + stage.name }}</td>
                    </tr>
                    <tr>
                        <th class="text-left">COMPOSIÇÃO</th>
                        <th class="text-right">UNID</th>
                        <th class="text-right">QUANTIDADE</th>
                        <th class="text-right">P UNIT</th>
                        <th class="text-right">SUB-TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(composition,index) in stage.composition" :key="index">
                        <td>{{composition.name}}</td>
                        <td width="130" class="text-right" >{{composition.unity }}</td>
                        <td width="130" class="text-right">{{formatMoney(composition.pivot.quantity) }}</td>
                        <td width="130" class="text-right">{{formatMoney(composition.total_with_tax)}}</td>
                        <td width="130" class="text-right">{{formatMoney(composition.total_with_tax * composition.pivot.quantity) }}</td>

                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="5" class="text-right" style="background:#d3d3d3; color: black;">TOTAL: {{formatMoney(stage.total)}}</td>
                    </tr>
                </tfoot>
            </v-simple-table>
        </v-card>
    </v-container>
</template>

<script>
import service from "../service";
import { mapMutations,mapActions } from "vuex";

export default {
    data: () => ({
        costId: null,
        stages: [],
        budgetId: null,
        grandTotal: 0,
        loading_table: false,
        toolItems: [
            { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },

        ],
    }),
    computed: {

    },

    watch: {

    },

    created() {
        this.costId = this.$route.params.cost_id;
        this.getStages();
    },
    methods: {
        ...mapMutations('ReportComposition', ['setStage']),
        ...mapActions('ReportComposition', ['getStageCompositions']),
        getStages() {
            this.loading_table = true
             this.getStageCompositions(this.costId) //TODO Fazer a paginação
                .then((result) => {
                    this.stages = result.data;
                    this.calcGrandTotal()
                })
                .catch()
                .finally(()=>{
                    this.loading_table = false
                });
        },
        actionMenuTool(action) {
            switch (action) {
                case "export_excel":
                    service.exportStageExcel(this.costId)
                        .then((response) => {

                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'RELATORIO_ETAPAS.xlsx');
                            document.body.appendChild(fileLink);
                            fileLink.click();

                        }).catch((response) => {
                            console.log(response)
                        })
                    break;
            }
        },

        totalStage(stage) {
            let totalStage = stage.composition.reduce((acumulator, composition) => {
                return acumulator + (composition.total_with_tax * composition.pivot.quantity)
            }, 0)


            return this.formatMoney(totalStage)
        },
        calcGrandTotal() {
            this.stages.forEach((stage) => {
                let totalStage = stage.composition.reduce((acumulator, composition) => {
                    return acumulator + (composition.total_with_tax * composition.pivot.quantity)
                }, 0)
                stage.total = totalStage
                //this.grandTotal += totalStage
            })

        },
        showReportCompositions(stage) {
            this.setStage(stage)
            this.$router.push('/system/report/composition/stages/compositions/' + stage.id)
        },
        showReportAbcCompositions() {
            this.$router.push('/system/report/composition/abc/compositions/' + this.costId)
        },

        formatMoney(number) {
            number = this.parseNumber(number);
            return number.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>

